import React, { Component, Fragment, useEffect } from 'react'
import $ from 'jquery'

export class Home extends Component {
    render() {
        return (
            <Fragment>
                {/* <div id="preloader-active">
                    <div className="preloader d-flex align-items-center justify-content-center">
                        <div className="preloader-inner position-relative">
                            <div className="preloader-circle"></div>
                            <div className="preloader-img pere-text">
                                <img src="assets/img/icon/loder.png" alt="loder" />
                            </div>
                        </div>
                    </div>
                </div>

                <header>
                    <div className="header-area">
                        <div className="main-header">
                            <div className="header-top header-sticky">
                                <div className="container">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap position-relative">

                                        <div className="left-side d-flex align-items-center">
                                            <div className="logo">
                                                <a href="index-2.html"><img src="assets/img/logo/logo.png" /></a>
                                            </div>

                                            <div className="main-menu d-none d-lg-block">
                                                <nav>
                                                    <ul id="navigation">
                                                        <li><a href="index-2.html">Home</a></li>
                                                        <li><a href="property.html">Property</a></li>
                                                        <li><a href="about.html">About</a></li>
                                                        <li><a href="blog.html">Blog</a>
                                                            <ul className="submenu">
                                                                <li><a href="blog.html">Blog</a></li>
                                                                <li><a href="blog_details.html">Blog Details</a></li>
                                                                <li><a href="elements.html">Elements</a></li>
                                                            </ul>
                                                        </li>
                                                        <li><a href="contact.html">Contact</a></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="header-right-btn f-right">
                                            <a href="#" className="header-btn2">Call Us: <span>+10 (78) 356 3276</span></a>
                                            <a href="login.html" className="btn_1">Sign In</a>
                                        </div>

                                        <div className="col-12">
                                            <div className="mobile_menu d-block d-lg-none"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="header-bottom">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-xxl-8 col-xl-10 col-lg-11">

                                            <form action="#" className="search-box">
                                                <div className="input-form">
                                                    <input type="text" placeholder="Enter Keyword.." />

                                                    <div className="icon">
                                                        <i className="ti-search"></i>
                                                    </div>
                                                </div>
                                                <div className="select-form">
                                                    <div className="select-itms">
                                                        <select name="select" id="select1">
                                                            <option value>Rent</option>
                                                            <option value>Rent 1</option>
                                                            <option value>Rent 2</option>
                                                            <option value>Rent 3</option>
                                                            <option value>Rent 4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                                <div className="select-form select-form2">
                                                    <div className="select-itms">
                                                        <select name="select" id="select2">
                                                            <option value>Bed</option>
                                                            <option value>Bed 1</option>
                                                            <option value>Bed 2</option>
                                                            <option value>Bed 3</option>
                                                            <option value>Bed 4</option>
                                                        </select>
                                                        <div className="select-items">
                                                            <img src="assets/img/icon/bed.svg" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="select-form select-form2">
                                                    <div className="select-itms">
                                                        <select name="select" id="select3">
                                                            <option value>Bath</option>
                                                            <option value>Bath 1</option>
                                                            <option value>Bath 2</option>
                                                            <option value>Bath 3</option>
                                                            <option value>Bath 4</option>
                                                        </select>
                                                    </div>
                                                    <div className="select-items">
                                                        <img src="assets/img/icon/bath.svg" />
                                                    </div>
                                                </div>
                                                <div className="search-form">
                                                    <button>Search</button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header> */}
                <main>

                    <section className="slider-area ">
                        <div className="slider-active">

                            <div className="single-slider slider-bg1 hero-overly slider-height d-flex align-items-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="offset-xl-1 col-xxl-5 col-xl-6 col-lg-6 col-md-8">
                                            <div className="hero-caption">
                                                {/* <span>3 Bed - 2 Bath - 2200 Sq Ft</span> */}
                                                <span>From 353 Sq Ft to 1050 Sq Ft</span>
                                                <h1 data-animation="bounceIn" data-delay="0.2s">Premium Offices & Show Room</h1>
                                                <p data-animation="fadeInUp" data-delay="0.4s" style={{width:'25rem'}}>In the vast expanse of the cosmos, Mercury Corporate Space stands as the beacon of innovation, meticulously crafting commercial realms that not only redefine interstellar real estate but also propel businesses light years ahead.</p>
                                                {/* <span className="price">$ 3,272</span> */}
                                                {/* <a href="#" className="btn hero-btn" data-animation="fadeInUp" data-delay="0.7s">View Property</a> */}
                                                <a href="assets/brochure/Mercury_Digital_Brochure.pdf" target="_blank" className="btn hero-btn" data-animation="fadeInUp" data-delay="0.7s">View Brochure</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="latest-items section-padding">
                        <div className="container">
                            <div className="row justify-content-center">
                                {/* <div className="col-xxl-6 col-xl-7"> */}
                                <div className="col-xxl-12 col-xl-12">

                                    <div className="section-tittle mb-50 text-center ">
                                        {/* <h2>Display Latest & Featured Properties</h2> */}
                                        <h2>Mercury Vistas: Business Beyond Boundaries</h2>
                                        <p>At Mercury Corporate Space, we believe in redefining boundaries. From contemporary showrooms to visionary office spaces with breathtaking balcony views, we curate environments that drive the future of commerce.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="items-active">
                                <div className="single-cat mb-30">
                                    <div className="cat-img">
                                        <img src="assets/img/home/office.png" />
                                    </div>
                                    <div className="cat-cap">
                                        <div className="cap-top">
                                            {/* <span>$ 6728</span> */}
                                            <h5><a href="#">Offices</a></h5>
                                            <p>Step into a realm of productivity and innovation with our cutting-edge office spaces. Designed for modern professionals, every detail reflects precision and purpose, offering a seamless blend of comfort and functionality. This is where ideas take flight and businesses soar to new heights.</p>
                                        </div>
                                        {/* <div className="pricing d-flex justify-content-center align-items-center">
                                            <div className="single">
                                                <img src="assets/img/icon/bed.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/bath.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/building.svg" />
                                                <span>1050 Sq Ft</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="single-cat mb-30">
                                    <div className="cat-img">
                                        <img src="assets/img/home/office-with-balcony.jpg" />
                                    </div>
                                    <div className="cat-cap">
                                        <div className="cap-top">
                                            {/* <span>$ 6728</span> */}
                                            <h5><a href="#">Offices with Balcony</a></h5>
                                            <p>Elevate your work experience to celestial levels. Our offices with balconies offer more than just space—they offer inspiration. Gaze upon the cosmos as you strategize and ideate, allowing the vastness of the universe to expand your horizons. This isn't just an office; it's an experience.</p>
                                        </div>
                                        {/* <div className="pricing d-flex justify-content-center align-items-center">
                                            <div className="single">
                                                <img src="assets/img/icon/bed.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/bath.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/building.svg" />
                                                <span>1050 Sq Ft</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="single-cat mb-30">
                                    <div className="cat-img">
                                        <img src="assets/img/home/showrooms.png" />
                                    </div>
                                    <div className="cat-cap">
                                        <div className="cap-top">
                                            {/* <span>$ 6728</span> */}
                                            <h5><a href="#">Showrooms</a></h5>
                                            <p>Showcase your brand's brilliance in the most magnificent setting. Our state-of-the-art showrooms are curated for the discerning, providing an environment that not only highlights your products but also tells a story. From optimal lighting to sleek designs, every element is crafted to accentuate and amplify.</p>
                                        </div>
                                        {/* <div className="pricing d-flex justify-content-center align-items-center">
                                            <div className="single">
                                                <img src="assets/img/icon/bed.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/bath.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/building.svg" />
                                                <span>1050 Sq Ft</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {/* <div className="single-cat mb-30">
                                    <div className="cat-img">
                                        <img src="assets/img/gallery/items2.jpg" />
                                    </div>
                                    <div className="cat-cap">
                                        <div className="cap-top">
                                            <span>$ 6728</span>
                                            <h5><a href="#">Appartment</a></h5>
                                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                        </div>
                                        <div className="pricing d-flex justify-content-between align-items-center">
                                            <div className="single">
                                                <img src="assets/img/icon/bed.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/bath.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/building.svg" />
                                                <span>1050 Sq Ft</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </section>


                    <section className="services-area section-img-bg2" data-background="assets/img/gallery/section-bg1.jpg">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 ">

                                    <div className="section-tittle section-tittle2  text-center mb-40">
                                        <h2>How we help people?</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-xl-4  col-lg-4 col-md-4 col-sm-12 p-0">
                                    <div className="single-cat mb-10 text-center">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/services1.svg" />
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Comprehensive Consultation</a></h5>
                                            <p>At Mercury Corporate Space, we begin by deeply understanding your unique needs. Through a thorough consultation process, we gain insight into your business aspirations, growth plans, and spatial preferences, ensuring that our solutions align perfectly with your vision.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4  col-lg-4 col-md-4 col-sm-12 p-0">
                                    <div className="single-cat mb-10 text-center">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/services2.svg" />
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Tailored Space Solutions</a></h5>
                                            <p>Our team of expert designers and architects meticulously craft spaces based on your input and our industry insights. Whether it's a high-tech office, a serene balcony workspace, or a captivating showroom, we provide spaces that not only fit but also enhance your brand and workflow.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4  col-lg-4 col-md-4 col-sm-12 p-0">
                                    <div className="single-cat mb-10 text-center">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/services3.svg" />
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Seamless Transition & Support</a></h5>
                                            <p>Moving into a new space can be daunting, but with Mercury Corporate Space, it's effortless. We assist in facilitating a smooth transition to your new environment. Plus, our post-move-in support ensures you have everything you need to prosper, from tech assistance to maintenance and beyond.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* <section className="latest-items section-padding">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-6">

                                    <div className="section-tittle mb-50 text-center ">
                                        <h2>Properties for Sale</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="items-active">
                                <div className="single-cat mb-30">
                                    <div className="cat-img">
                                        <img src="assets/img/gallery/items1.jpg" />
                                    </div>
                                    <div className="cat-cap">
                                        <div className="cap-top">
                                            <span>$ 6728</span>
                                            <h5><a href="#">Appartment</a></h5>
                                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                        </div>
                                        <div className="pricing d-flex justify-content-between align-items-center">
                                            <div className="single">
                                                <img src="assets/img/icon/bed.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/bath.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/building.svg" />
                                                <span>1050 Sq Ft</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-cat mb-30">
                                    <div className="cat-img">
                                        <img src="assets/img/gallery/items2.jpg" />
                                    </div>
                                    <div className="cat-cap">
                                        <div className="cap-top">
                                            <span>$ 6728</span>
                                            <h5><a href="#">Office</a></h5>
                                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                        </div>
                                        <div className="pricing d-flex justify-content-between align-items-center">
                                            <div className="single">
                                                <img src="assets/img/icon/bed.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/bath.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/building.svg" />
                                                <span>1050 Sq Ft</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-cat mb-30">
                                    <div className="cat-img">
                                        <img src="assets/img/gallery/items1.jpg" />
                                    </div>
                                    <div className="cat-cap">
                                        <div className="cap-top">
                                            <span>$ 6728</span>
                                            <h5><a href="#">Classic home</a></h5>
                                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                        </div>
                                        <div className="pricing d-flex justify-content-between align-items-center">
                                            <div className="single">
                                                <img src="assets/img/icon/bed.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/bath.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/building.svg" />
                                                <span>1050 Sq Ft</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-cat mb-30">
                                    <div className="cat-img">
                                        <img src="assets/img/gallery/items2.jpg" />
                                    </div>
                                    <div className="cat-cap">
                                        <div className="cap-top">
                                            <span>$ 6728</span>
                                            <h5><a href="#">Appartment</a></h5>
                                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                        </div>
                                        <div className="pricing d-flex justify-content-between align-items-center">
                                            <div className="single">
                                                <img src="assets/img/icon/bed.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/bath.svg" />
                                                <span>2 Bedroom</span>
                                            </div>
                                            <div className="single">
                                                <img src="assets/img/icon/building.svg" />
                                                <span>1050 Sq Ft</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}


                    <section className="categories-area section-bg">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-xl-4">
                                    <div className="section-tittle section-tittle3 mb-20">
                                        <h2>Explore<br /> by Property Type</h2>
                                        <p>Elevate your workspace experience with offices that offer expansive cosmic views.</p>
                                        {/* <a href="#" className="btn mt-30">View All Property</a> */}
                                        <a href="assets/brochure/Mercury_Digital_Brochure.pdf" target="_blank" className="btn mt-30">View Brochure</a>
                                    </div>
                                </div>
                                {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 p-0">
                                    <div className="single-cat text-center mb-10">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/property1.svg" />
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Home & Appartment</a></h5>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 p-0">
                                    <div className="single-cat text-center mb-10">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/property4.svg" />
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Office</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 p-0">
                                    <div className="single-cat text-center mb-10">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/property3.svg" />
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Office with Balcony</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 p-0">
                                    <div className="single-cat text-center mb-10">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/property2.svg" />
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Showrooms</a></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* <div className="team-area section-padding">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-6 col-lg-8 col-md-10">

                                    <div className="section-tittle text-center mb-40">
                                        <h2>Our Agents</h2>
                                        <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                    </div>
                                </div>
                            </div>
                            <div className="team-active">
                                <div className="single-team text-center">
                                    <div className="team-img">
                                        <img src="assets/img/gallery/team1.jpg" />

                                        <div className="team-social">
                                            <ul>
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fas fa-globe"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="team-caption">
                                        <h3><a href="#">Buster Hyman</a></h3>
                                        <p>Real Estate Agent</p>
                                    </div>
                                </div>
                                <div className="single-team text-center">
                                    <div className="team-img">
                                        <img src="assets/img/gallery/team2.jpg" />

                                        <div className="team-social">
                                            <ul>
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fas fa-globe"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="team-caption">
                                        <h3><a href="#">Holly Graham</a></h3>
                                        <p>Real Estate Agent</p>
                                    </div>
                                </div>
                                <div className="single-team text-center">
                                    <div className="team-img">
                                        <img src="assets/img/gallery/team3.jpg" />

                                        <div className="team-social">
                                            <ul>
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fas fa-globe"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="team-caption">
                                        <h3><a href="#">Nick R. Bocker</a></h3>
                                        <p>Real Estate Agent</p>
                                    </div>
                                </div>
                                <div className="single-team text-center">
                                    <div className="team-img">
                                        <img src="assets/img/gallery/team4.jpg" />

                                        <div className="team-social">
                                            <ul>
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fas fa-globe"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="team-caption">
                                        <h3><a href="#">Jimmy Changa</a></h3>
                                        <p>Real Estate Agent</p>
                                    </div>
                                </div>
                                <div className="single-team text-center">
                                    <div className="team-img">
                                        <img src="assets/img/gallery/team2.jpg" />

                                        <div className="team-social">
                                            <ul>
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fas fa-globe"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="team-caption">
                                        <h3><a href="#">Buster Hyman</a></h3>
                                        <p>Real Estate Agent</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    {/* <div className="visit-tailor-area fix">

                        <div className="tailor-details">
                            <h2>Subscribe Newsletter</h2>
                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                            <div className="subscribe-now">
                                <input type="text" placeholder="Enter your email" />
                                <button className="submit-btn2">Subscribe</button>
                            </div>
                        </div>

                        <div className="tailor-offers"></div>
                    </div> */}

                </main>
                {/* <footer>
                    <div className="footer-wrapper">

                        <div className="footer-area footer-padding">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-8">
                                        <div className="single-footer-caption mb-50">
                                            <div className="single-footer-caption mb-30">

                                                <div className="footer-logo mb-35">
                                                    <a href="index-2.html"><img src="assets/img/logo/logo2_footer.png" /></a>
                                                </div>
                                                <div className="footer-tittle">
                                                    <div className="footer-pera">
                                                        <p>Duis aute irure dolor inasfa reprehenderit in voluptate velit esse cillum</p>
                                                    </div>

                                                    <ul className="footer-social">
                                                        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                                        <li><a href="https://bit.ly/sai4ull"><i className="fab fa-facebook"></i></a></li>
                                                        <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                                        <li> <a href="#"><i className="fab fa-youtube"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Navigation</h4>
                                                <ul>
                                                    <li><a href="#">Home</a></li>
                                                    <li><a href="#">About</a></li>
                                                    <li><a href="#">Services</a></li>
                                                    <li><a href="#">Blog</a></li>
                                                    <li><a href="#">Contact</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Services</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li><a href="#"> Real State</a></li>
                                                    <li><a href="#">Commercial</a></li>
                                                    <li><a href="#">Construction</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Support</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li><a href="#"> Real State</a></li>
                                                    <li><a href="#">Commercial</a></li>
                                                    <li><a href="#">Construction</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-8">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle mb-50">
                                                <h4>Contact Us</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li className="number2"><a href="#"><span className="__cf_email__" data-cfemail="b7d4d8d9c3d6d4c3f7d4d6c5c0d6c4df99d4d8da">[email&#160;protected]</span></a></li>
                                                    <li className="number"><a href="#">10 (87) 738-3940</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-bottom-area">
                            <div className="container">
                                <div className="footer-border">
                                    <div className="row">
                                        <div className="col-xl-12 ">
                                            <div className="footer-copy-right text-center">
                                                <p>Copyright &copy;<script data-cfasync="false" src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart color-danger" aria-hidden="true"></i> by <a href="https://colorlib.com/" target="_blank" rel="nofollow noopener">Colorlib</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </footer>

                <div id="back-top">
                    <a className="wrapper" title="Go to Top" href="#">
                        <div className="arrows-container">
                            <div className="arrow arrow-one">
                            </div>
                            <div className="arrow arrow-two">
                            </div>
                        </div>
                    </a>
                </div> */}
            </Fragment>
        )
    }
}

export default Home
