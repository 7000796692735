import React, { Component } from 'react'

export class About extends Component {
    render() {
        return (
            <div>
                {/* <div id="preloader-active">
                    <div className="preloader d-flex align-items-center justify-content-center">
                        <div className="preloader-inner position-relative">
                            <div className="preloader-circle"></div>
                            <div className="preloader-img pere-text">
                                <img src="assets/img/icon/loder.png" alt="loder"/>
                            </div>
                        </div>
                    </div>
                </div>

                <header>
                    <div className="header-area">
                        <div className="main-header">
                            <div className="header-top header-sticky">
                                <div className="container">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap position-relative">

                                        <div className="left-side d-flex align-items-center">
                                            <div className="logo">
                                                <a href="index-2.html"><img src="assets/img/logo/logo.png" alt/></a>
                                            </div>

                                            <div className="main-menu d-none d-lg-block">
                                                <nav>
                                                    <ul id="navigation">
                                                        <li><a href="index-2.html">Home</a></li>
                                                        <li><a href="property.html">Property</a></li>
                                                        <li><a href="about.html">About</a></li>
                                                        <li><a href="blog.html">Blog</a>
                                                            <ul className="submenu">
                                                                <li><a href="blog.html">Blog</a></li>
                                                                <li><a href="blog_details.html">Blog Details</a></li>
                                                                <li><a href="elements.html">Elements</a></li>
                                                            </ul>
                                                        </li>
                                                        <li><a href="contact.html">Contact</a></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="header-right-btn f-right">
                                            <a href="#" className="header-btn2">Call Us: <span>+10 (78) 356 3276</span></a>
                                            <a href="login.html" className="btn_1">Sign In</a>
                                        </div>

                                        <div className="col-12">
                                            <div className="mobile_menu d-block d-lg-none"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="header-bottom">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-xxl-8 col-xl-10 col-lg-11">

                                            <form action="#" className="search-box">
                                                <div className="input-form">
                                                    <input type="text" placeholder="Enter Keyword.."/>

                                                        <div className="icon">
                                                            <i className="ti-search"></i>
                                                        </div>
                                                </div>
                                                <div className="select-form">
                                                    <div className="select-itms">
                                                        <select name="select" id="select1">
                                                            <option value>Rent</option>
                                                            <option value>Rent 1</option>
                                                            <option value>Rent 2</option>
                                                            <option value>Rent 3</option>
                                                            <option value>Rent 4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="select-form select-form2">
                                                    <div className="select-itms">
                                                        <select name="select" id="select2">
                                                            <option value>Bed</option>
                                                            <option value>Bed 1</option>
                                                            <option value>Bed 2</option>
                                                            <option value>Bed 3</option>
                                                            <option value>Bed 4</option>
                                                        </select>
                                                        <div className="select-items">
                                                            <img src="assets/img/icon/bed.svg" alt/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="select-form select-form2">
                                                    <div className="select-itms">
                                                        <select name="select" id="select3">
                                                            <option value>Bath</option>
                                                            <option value>Bath 1</option>
                                                            <option value>Bath 2</option>
                                                            <option value>Bath 3</option>
                                                            <option value>Bath 4</option>
                                                        </select>
                                                    </div>
                                                    <div className="select-items">
                                                        <img src="assets/img/icon/bath.svg" alt/>
                                                    </div>
                                                </div>
                                                <div className="search-form">
                                                    <button>Search</button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header> */}
                <main>

                    <div className="slider-area">
                        <div className="single-slider slider-bg3 hero-overly slider-height2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-10">

                                        <div className="hero-caption hero-caption4">
                                            <h1>About Us</h1>
                                            <p>Delve into the cosmic journey of Mercury Corporate Space, where our legacy of pioneering celestial commercial ventures meets a passion for crafting stellar experiences.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className="about-area1 about-area2" style={{ padding: 30 }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            {/* <h2>Just browse away.<br/>
                                                It’s all here.</h2>
                                            <p className="mb-20 mt-20">Rhoncus est pellen tesque elit ullam corper dignissim cras
                                                tincidunt lobortis feugiat. Et netus malesuada fames.</p>
                                            <p className="mb-50">Rhoncus est pellen tesque elit ullam corper dignissim cras
                                                tincidunt lobortis feugiat. Et netus malesuada fames.</p> */}
                                            <h2>Introduction</h2>
                                            <p><b>MERCURY CORPORATE SPACE</b> is a mixed-use development located in rajkot that features office and community arts space. MERCURY CORPORATE SPACE made the list because of its use of history and the local community to tell the story of the property. It also features user-friendly navigation to find all of the important information.</p>
                                        </div>
                                        {/* <a href="#" className="btn">View All Property</a> */}
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/about/About_01.webp" alt="about1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="about-area1 about-area2" style={{ padding: 30 }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/about/About_03.webp" alt="about"/>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            {/* <h2>Just browse away.<br/>
                                                It’s all here.</h2>
                                            <p className="mb-20 mt-20">Rhoncus est pellen tesque elit ullam corper dignissim cras
                                                tincidunt lobortis feugiat. Et netus malesuada fames.</p>
                                            <p className="mb-50">Rhoncus est pellen tesque elit ullam corper dignissim cras
                                                tincidunt lobortis feugiat. Et netus malesuada fames.</p> */}
                                            <h2>Vision</h2>
                                            <p><b>MERCURY CORPORATE SPACE</b> sets itself apart by highlighting the history of RAJKOT'S uptown neighborhood. Only one page focuses on the building itself. Called "The Project", this page pays homage to the history of the area with photos from the past, next to images of the new development. This continues the connection to the community and shows how the project will revitalize the area..</p>
                                        </div>
                                        {/* <a href="#" className="btn">View All Property</a> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="about-area1 about-area2" style={{ padding: 30 }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            {/* <h2>Just browse away.<br/>
                                                It’s all here.</h2>
                                            <p className="mb-20 mt-20">Rhoncus est pellen tesque elit ullam corper dignissim cras
                                                tincidunt lobortis feugiat. Et netus malesuada fames.</p>
                                            <p className="mb-50">Rhoncus est pellen tesque elit ullam corper dignissim cras
                                                tincidunt lobortis feugiat. Et netus malesuada fames.</p> */}
                                            <h2>Mission</h2>
                                            <p>Our mission revolves around community and inclusivity. Our "Community" page is a testament to this commitment, focusing on how the local community has profoundly impacted our project. We proudly showcase community meetings with Rajkot residents and stakeholder engagements with local groups. Our dedication to diversity and uniqueness is evident here, underlining our promise to make <b>MERCURY CORPORATE SPACE</b> a thriving hub for all. Additionally, our "Local Impact" page illustrates how our project will bring substantial benefits to Rajkot, including increased tax revenue, enhanced employment opportunities, and unwavering support for small local businesses.</p>
                                        </div>
                                        {/* <a href="#" className="btn">View All Property</a> */}
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/about/About_02.webp" alt="about"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* <section className="services-area section-img-bg2" data-background="assets/img/gallery/section-bg1.jpg">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 ">

                                    <div className="section-tittle section-tittle2  text-center mb-40">
                                        <h2>How we help people?</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-xl-3  col-lg-4 col-md-4 col-sm-6 p-0">
                                    <div className="single-cat mb-10 text-center">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/services1.svg" alt="about""about""about"/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Sell home or office</a></h5>
                                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3  col-lg-4 col-md-4 col-sm-6 p-0">
                                    <div className="single-cat mb-10 text-center">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/services2.svg" alt="about""about"/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Rent home or office</a></h5>
                                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3  col-lg-4 col-md-4 col-sm-6 p-0">
                                    <div className="single-cat mb-10 text-center">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/services3.svg" alt="about""about""about""about"/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Find next</a></h5>
                                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className="services-area section-img-bg2" data-background="assets/img/gallery/section-bg1.jpg">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 ">

                                    <div className="section-tittle text-center mb-40">
                                        <h2>How we help people?</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-xl-4  col-lg-4 col-md-4 col-sm-12 p-0">
                                    <div className="single-cat mb-10 text-center">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/services1.svg" />
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Comprehensive Consultation</a></h5>
                                            <p>At Mercury Corporate Space, we begin by deeply understanding your unique needs. Through a thorough consultation process, we gain insight into your business aspirations, growth plans, and spatial preferences, ensuring that our solutions align perfectly with your vision.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4  col-lg-4 col-md-4 col-sm-12 p-0">
                                    <div className="single-cat mb-10 text-center">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/services2.svg" />
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Tailored Space Solutions</a></h5>
                                            <p>Our team of expert designers and architects meticulously craft spaces based on your input and our industry insights. Whether it's a high-tech office, a serene balcony workspace, or a captivating showroom, we provide spaces that not only fit but also enhance your brand and workflow.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4  col-lg-4 col-md-4 col-sm-12 p-0">
                                    <div className="single-cat mb-10 text-center">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/services3.svg" />
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Seamless Transition & Support</a></h5>
                                            <p>Moving into a new space can be daunting, but with Mercury Corporate Space, it's effortless. We assist in facilitating a smooth transition to your new environment. Plus, our post-move-in support ensures you have everything you need to prosper, from tech assistance to maintenance and beyond.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* <div className="team-area section-padding">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-6 col-lg-8 col-md-10">

                                    <div className="section-tittle text-center mb-40">
                                        <h2>Our Agents</h2>
                                        <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                    </div>
                                </div>
                            </div>
                            <div className="team-active">
                                <div className="single-team text-center">
                                    <div className="team-img">
                                        <img src="assets/img/gallery/team1.jpg" alt="about""about"/>

                                        <div className="team-social">
                                            <ul>
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fas fa-globe"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="team-caption">
                                        <h3><a href="#">Buster Hyman</a></h3>
                                        <p>Real Estate Agent</p>
                                    </div>
                                </div>
                                <div className="single-team text-center">
                                    <div className="team-img">
                                        <img src="assets/img/gallery/team2.jpg" alt="about"/>

                                        <div className="team-social">
                                            <ul>
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fas fa-globe"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="team-caption">
                                        <h3><a href="#">Holly Graham</a></h3>
                                        <p>Real Estate Agent</p>
                                    </div>
                                </div>
                                <div className="single-team text-center">
                                    <div className="team-img">
                                        <img src="assets/img/gallery/team3.jpg" alt="about"/>

                                        <div className="team-social">
                                            <ul>
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fas fa-globe"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="team-caption">
                                        <h3><a href="#">Nick R. Bocker</a></h3>
                                        <p>Real Estate Agent</p>
                                    </div>
                                </div>
                                <div className="single-team text-center">
                                    <div className="team-img">
                                        <img src="assets/img/gallery/team4.jpg" alt="about"/>

                                        <div className="team-social">
                                            <ul>
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fas fa-globe"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="team-caption">
                                        <h3><a href="#">Jimmy Changa</a></h3>
                                        <p>Real Estate Agent</p>
                                    </div>
                                </div>
                                <div className="single-team text-center">
                                    <div className="team-img">
                                        <img src="assets/img/gallery/team2.jpg" alt="about"/>

                                        <div className="team-social">
                                            <ul>
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fas fa-globe"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="team-caption">
                                        <h3><a href="#">Buster Hyman</a></h3>
                                        <p>Real Estate Agent</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    {/* <div className="visit-tailor-area fix">

                        <div className="tailor-details">
                            <h2>Subscribe Newsletter</h2>
                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                            <div className="subscribe-now">
                                <input type="text" placeholder="Enter your email" />
                                <button className="submit-btn2">Subscribe</button>
                            </div>
                        </div>

                        <div className="tailor-offers"></div>
                    </div> */}

                </main>
                {/* <footer>
                    <div className="footer-wrapper">

                        <div className="footer-area footer-padding">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-8">
                                        <div className="single-footer-caption mb-50">
                                            <div className="single-footer-caption mb-30">

                                                <div className="footer-logo mb-35">
                                                    <a href="index-2.html"><img src="assets/img/logo/logo2_footer.png" alt/></a>
                                                </div>
                                                <div className="footer-tittle">
                                                    <div className="footer-pera">
                                                        <p>Duis aute irure dolor inasfa reprehenderit in voluptate velit esse cillum</p>
                                                    </div>

                                                    <ul className="footer-social">
                                                        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                                        <li><a href="https://bit.ly/sai4ull"><i className="fab fa-facebook"></i></a></li>
                                                        <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                                        <li> <a href="#"><i className="fab fa-youtube"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Navigation</h4>
                                                <ul>
                                                    <li><a href="#">Home</a></li>
                                                    <li><a href="#">About</a></li>
                                                    <li><a href="#">Services</a></li>
                                                    <li><a href="#">Blog</a></li>
                                                    <li><a href="#">Contact</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Services</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li><a href="#"> Real State</a></li>
                                                    <li><a href="#">Commercial</a></li>
                                                    <li><a href="#">Construction</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Support</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li><a href="#"> Real State</a></li>
                                                    <li><a href="#">Commercial</a></li>
                                                    <li><a href="#">Construction</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-8">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle mb-50">
                                                <h4>Contact Us</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li className="number2"><a href="#"><span className="__cf_email__" data-cfemail="6f0c00011b0e0c1b2f0c0e1d180e1c07410c0002">[email&#160;protected]</span></a></li>
                                                    <li className="number"><a href="#">10 (87) 738-3940</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-bottom-area">
                            <div className="container">
                                <div className="footer-border">
                                    <div className="row">
                                        <div className="col-xl-12 ">
                                            <div className="footer-copy-right text-center">
                                                <p>Copyright &copy;<script data-cfasync="false" src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart color-danger" aria-hidden="true"></i> by <a href="https://colorlib.com/" target="_blank" rel="nofollow noopener">Colorlib</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </footer> */}

                {/* <div id="back-top">
                    <a className="wrapper" title="Go to Top" href="#">
                        <div className="arrows-container">
                            <div className="arrow arrow-one">
                            </div>
                            <div className="arrow arrow-two">
                            </div>
                        </div>
                    </a>
                </div> */}
            </div>
        )
    }
}

export default About
