import React, { Component } from 'react'

export class BlogDetails extends Component {
    render() {
        return (
            <div>
                {/* <div id="preloader-active">
                    <div className="preloader d-flex align-items-center justify-content-center">
                        <div className="preloader-inner position-relative">
                            <div className="preloader-circle"></div>
                            <div className="preloader-img pere-text">
                                <img src="assets/img/icon/loder.png" alt="loder"/>
                            </div>
                        </div>
                    </div>
                </div>

                <header>
                    <div className="header-area">
                        <div className="main-header">
                            <div className="header-top header-sticky">
                                <div className="container">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap position-relative">

                                        <div className="left-side d-flex align-items-center">
                                            <div className="logo">
                                                <a href="index-2.html"><img src="assets/img/logo/logo.png" alt/></a>
                                            </div>

                                            <div className="main-menu d-none d-lg-block">
                                                <nav>
                                                    <ul id="navigation">
                                                        <li><a href="index-2.html">Home</a></li>
                                                        <li><a href="property.html">Property</a></li>
                                                        <li><a href="about.html">About</a></li>
                                                        <li><a href="blog.html">Blog</a>
                                                            <ul className="submenu">
                                                                <li><a href="blog.html">Blog</a></li>
                                                                <li><a href="blog_details.html">Blog Details</a></li>
                                                                <li><a href="elements.html">Elements</a></li>
                                                            </ul>
                                                        </li>
                                                        <li><a href="contact.html">Contact</a></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="header-right-btn f-right">
                                            <a href="#" className="header-btn2">Call Us: <span>+10 (78) 356 3276</span></a>
                                            <a href="login.html" className="btn_1">Sign In</a>
                                        </div>

                                        <div className="col-12">
                                            <div className="mobile_menu d-block d-lg-none"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="header-bottom">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-xxl-8 col-xl-10 col-lg-11">

                                            <form action="#" className="search-box">
                                                <div className="input-form">
                                                    <input type="text" placeholder="Enter Keyword.."/>

                                                        <div className="icon">
                                                            <i className="ti-search"></i>
                                                        </div>
                                                </div>
                                                <div className="select-form">
                                                    <div className="select-itms">
                                                        <select name="select" id="select1">
                                                            <option value>Rent</option>
                                                            <option value>Rent 1</option>
                                                            <option value>Rent 2</option>
                                                            <option value>Rent 3</option>
                                                            <option value>Rent 4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="select-form select-form2">
                                                    <div className="select-itms">
                                                        <select name="select" id="select2">
                                                            <option value>Bed</option>
                                                            <option value>Bed 1</option>
                                                            <option value>Bed 2</option>
                                                            <option value>Bed 3</option>
                                                            <option value>Bed 4</option>
                                                        </select>
                                                        <div className="select-items">
                                                            <img src="assets/img/icon/bed.svg" alt/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="select-form select-form2">
                                                    <div className="select-itms">
                                                        <select name="select" id="select3">
                                                            <option value>Bath</option>
                                                            <option value>Bath 1</option>
                                                            <option value>Bath 2</option>
                                                            <option value>Bath 3</option>
                                                            <option value>Bath 4</option>
                                                        </select>
                                                    </div>
                                                    <div className="select-items">
                                                        <img src="assets/img/icon/bath.svg" alt/>
                                                    </div>
                                                </div>
                                                <div className="search-form">
                                                    <button>Search</button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header> */}
                <main>

                    <div className="slider-area">
                        <div className="single-slider slider-bg4 hero-overly slider-height2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-10">

                                        <div className="hero-caption hero-caption2">
                                            <h1>Blog Details</h1>
                                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className="blog_area single-post-area top-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 posts-list">
                                    <div className="single-post">
                                        <div className="feature-img">
                                            <img className="img-fluid" src="assets/img/blog/single_blog_1.jpg" alt/>
                                        </div>
                                        <div className="blog_details">
                                            <h2 style={{color: "#2d2d2d;"}}>Second divided from form fish beast made every of seas
                                                all gathered us saying he our
                                            </h2>
                                            <ul className="blog-info-link mt-3 mb-4">
                                                <li><a href="#"><i className="fa fa-user"></i> Travel, Lifestyle</a></li>
                                                <li><a href="#"><i className="fa fa-comments"></i> 03 Comments</a></li>
                                            </ul>
                                            <p className="excert">
                                                MCSE boot camps have its supporters and its detractors. Some people do not understand why you
                                                should have to spend money on boot camp when you can get the MCSE study materials yourself at a
                                                fraction of the camp price. However, who has the willpower
                                            </p>
                                            <p>
                                                MCSE boot camps have its supporters and its detractors. Some people do not understand why you
                                                should have to spend money on boot camp when you can get the MCSE study materials yourself at a
                                                fraction of the camp price. However, who has the willpower to actually sit through a
                                                self-imposed MCSE training. who has the willpower to actually
                                            </p>
                                            <div className="quote-wrapper">
                                                <div className="quotes">
                                                    MCSE boot camps have its supporters and its detractors. Some people do not understand why you
                                                    should have to spend money on boot camp when you can get the MCSE study materials yourself at
                                                    a fraction of the camp price. However, who has the willpower to actually sit through a
                                                    self-imposed MCSE training.
                                                </div>
                                            </div>
                                            <p>
                                                MCSE boot camps have its supporters and its detractors. Some people do not understand why you
                                                should have to spend money on boot camp when you can get the MCSE study materials yourself at a
                                                fraction of the camp price. However, who has the willpower
                                            </p>
                                            <p>
                                                MCSE boot camps have its supporters and its detractors. Some people do not understand why you
                                                should have to spend money on boot camp when you can get the MCSE study materials yourself at a
                                                fraction of the camp price. However, who has the willpower to actually sit through a
                                                self-imposed MCSE training. who has the willpower to actually
                                            </p>
                                        </div>
                                    </div>
                                    <div className="navigation-top">
                                        <div className="d-sm-flex justify-content-between text-center">
                                            <p className="like-info"><span className="align-middle"><i className="fa fa-heart"></i></span> Lily and 4
                                                people like this</p>
                                            <div className="col-sm-4 text-center my-2 my-sm-0">
                                            </div>
                                            <ul className="social-icons">
                                                <li><a href="https://www.facebook.com/sai4ull"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fab fa-dribbble"></i></a></li>
                                                <li><a href="#"><i className="fab fa-behance"></i></a></li>
                                            </ul>
                                        </div>
                                        <div className="navigation-area">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-12 nav-left flex-row d-flex justify-content-start align-items-center">
                                                    <div className="thumb">
                                                        <a href="#">
                                                            <img className="img-fluid" src="assets/img/post/preview.jpg" alt/>
                                                        </a>
                                                    </div>
                                                    <div className="arrow">
                                                        <a href="#">
                                                            <span className="lnr text-white ti-arrow-left"></span>
                                                        </a>
                                                    </div>
                                                    <div className="detials">
                                                        <p>Prev Post</p>
                                                        <a href="#">
                                                            <h4 style={{color: "#2d2d2d;"}}>Space The Final Frontier</h4>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12 nav-right flex-row d-flex justify-content-end align-items-center">
                                                    <div className="detials">
                                                        <p>Next Post</p>
                                                        <a href="#">
                                                            <h4 style={{color: "#2d2d2d;"}}>Telescopes 101</h4>
                                                        </a>
                                                    </div>
                                                    <div className="arrow">
                                                        <a href="#">
                                                            <span className="lnr text-white ti-arrow-right"></span>
                                                        </a>
                                                    </div>
                                                    <div className="thumb">
                                                        <a href="#">
                                                            <img className="img-fluid" src="assets/img/post/next.jpg" alt/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-author">
                                        <div className="media align-items-center">
                                            <img src="assets/img/blog/author.png" alt/>
                                                <div className="media-body">
                                                    <a href="#">
                                                        <h4>Harvard milan</h4>
                                                    </a>
                                                    <p>Second divided from form fish beast made. Every of seas all gathered use saying you're, he
                                                        our dominion twon Second divided from</p>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="comments-area">
                                        <h4>05 Comments</h4>
                                        <div className="comment-list">
                                            <div className="single-comment justify-content-between d-flex">
                                                <div className="user justify-content-between d-flex">
                                                    <div className="thumb">
                                                        <img src="assets/img/blog/comment_1.png" alt/>
                                                    </div>
                                                    <div className="desc">
                                                        <p className="comment">
                                                            Multiply sea night grass fourth day sea lesser rule open subdue female fill which them
                                                            Blessed, give fill lesser bearing multiply sea night grass fourth day sea lesser
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <h5>
                                                                    <a href="#">Emilly Blunt</a>
                                                                </h5>
                                                                <p className="date">December 4, 2017 at 3:12 pm </p>
                                                            </div>
                                                            <div className="reply-btn">
                                                                <a href="#" className="btn-reply text-uppercase">reply</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="comment-list">
                                            <div className="single-comment justify-content-between d-flex">
                                                <div className="user justify-content-between d-flex">
                                                    <div className="thumb">
                                                        <img src="assets/img/blog/comment_2.png" alt/>
                                                    </div>
                                                    <div className="desc">
                                                        <p className="comment">
                                                            Multiply sea night grass fourth day sea lesser rule open subdue female fill which them
                                                            Blessed, give fill lesser bearing multiply sea night grass fourth day sea lesser
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <h5>
                                                                    <a href="#">Emilly Blunt</a>
                                                                </h5>
                                                                <p className="date">December 4, 2017 at 3:12 pm </p>
                                                            </div>
                                                            <div className="reply-btn">
                                                                <a href="#" className="btn-reply text-uppercase">reply</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="comment-list">
                                            <div className="single-comment justify-content-between d-flex">
                                                <div className="user justify-content-between d-flex">
                                                    <div className="thumb">
                                                        <img src="assets/img/blog/comment_3.png" alt/>
                                                    </div>
                                                    <div className="desc">
                                                        <p className="comment">
                                                            Multiply sea night grass fourth day sea lesser rule open subdue female fill which them
                                                            Blessed, give fill lesser bearing multiply sea night grass fourth day sea lesser
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <h5>
                                                                    <a href="#">Emilly Blunt</a>
                                                                </h5>
                                                                <p className="date">December 4, 2017 at 3:12 pm </p>
                                                            </div>
                                                            <div className="reply-btn">
                                                                <a href="#" className="btn-reply text-uppercase">reply</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="comment-form">
                                        <h4>Leave a Reply</h4>
                                        <form className="form-contact comment_form" action="#" id="commentForm">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <textarea className="form-control w-100" name="comment" id="comment" cols="30" rows="9" placeholder="Write Comment"></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input className="form-control" name="name" id="name" type="text" placeholder="Name"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input className="form-control" name="email" id="email" type="email" placeholder="Email"/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <input className="form-control" name="website" id="website" type="text" placeholder="Website"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="button button-contactForm btn_1 boxed-btn">Post Comment</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget search_widget">
                                            <form action="#">
                                                <div className="form-group m-0">
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Search Keyword"/>
                                                            <div className="input-group-append d-flex">
                                                                <button className="boxed-btn2" type="button">Search</button>
                                                            </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </aside>
                                        <aside className="single_sidebar_widget post_category_widget">
                                            <h4 className="widget_title" style={{color: "#2d2d2d;"}}>Category</h4>
                                            <ul className="list cat-list">
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <p>Resaurant food</p>
                                                        <p>(37)</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <p>Travel news</p>
                                                        <p>(10)</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <p>Modern technology</p>
                                                        <p>(03)</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <p>Product</p>
                                                        <p>(11)</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <p>Inspiration</p>
                                                        <p>(21)</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <p>Health Care</p>
                                                        <p>(21)</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </aside>
                                        <aside className="single_sidebar_widget popular_post_widget">
                                            <h3 className="widget_title" style={{color: "#2d2d2d;"}}>Recent Post</h3>
                                            <div className="media post_item">
                                                <img src="assets/img/post/post_1.jpg" alt="post"/>
                                                    <div className="media-body">
                                                        <a href="blog_details.html">
                                                            <h3 style={{color: "#2d2d2d;"}}>From life was you fish...</h3>
                                                        </a>
                                                        <p>January 12, 2019</p>
                                                    </div>
                                            </div>
                                            <div className="media post_item">
                                                <img src="assets/img/post/post_2.jpg" alt="post"/>
                                                    <div className="media-body">
                                                        <a href="blog_details.html">
                                                            <h3 style={{color: "#2d2d2d;"}}>The Amazing Hubble</h3>
                                                        </a>
                                                        <p>02 Hours ago</p>
                                                    </div>
                                            </div>
                                            <div className="media post_item">
                                                <img src="assets/img/post/post_3.jpg" alt="post"/>
                                                    <div className="media-body">
                                                        <a href="blog_details.html">
                                                            <h3 style={{color: "#2d2d2d;"}}>Astronomy Or Astrology</h3>
                                                        </a>
                                                        <p>03 Hours ago</p>
                                                    </div>
                                            </div>
                                            <div className="media post_item">
                                                <img src="assets/img/post/post_4.jpg" alt="post"/>
                                                    <div className="media-body">
                                                        <a href="blog_details.html">
                                                            <h3 style={{color: "#2d2d2d;"}}>Asteroids telescope</h3>
                                                        </a>
                                                        <p>01 Hours ago</p>
                                                    </div>
                                            </div>
                                        </aside>
                                        <aside className="single_sidebar_widget tag_cloud_widget">
                                            <h4 className="widget_title" style={{color: "#2d2d2d;"}}>Tag Clouds</h4>
                                            <ul className="list">
                                                <li>
                                                    <a href="#">project</a>
                                                </li>
                                                <li>
                                                    <a href="#">love</a>
                                                </li>
                                                <li>
                                                    <a href="#">technology</a>
                                                </li>
                                                <li>
                                                    <a href="#">travel</a>
                                                </li>
                                                <li>
                                                    <a href="#">restaurant</a>
                                                </li>
                                                <li>
                                                    <a href="#">life style</a>
                                                </li>
                                                <li>
                                                    <a href="#">design</a>
                                                </li>
                                                <li>
                                                    <a href="#">illustration</a>
                                                </li>
                                            </ul>
                                        </aside>
                                        <aside className="single_sidebar_widget instagram_feeds">
                                            <h4 className="widget_title" style={{color: "#2d2d2d;"}}>Instagram Feeds</h4>
                                            <ul className="instagram_row flex-wrap">
                                                <li>
                                                    <a href="#">
                                                        <img className="img-fluid" src="assets/img/post/post_5.jpg" alt/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img className="img-fluid" src="assets/img/post/post_6.jpg" alt/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img className="img-fluid" src="assets/img/post/post_7.jpg" alt/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img className="img-fluid" src="assets/img/post/post_8.jpg" alt/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img className="img-fluid" src="assets/img/post/post_9.jpg" alt/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img className="img-fluid" src="assets/img/post/post_10.jpg" alt/>
                                                    </a>
                                                </li>
                                            </ul>
                                        </aside>
                                        <aside className="single_sidebar_widget newsletter_widget">
                                            <h4 className="widget_title" style={{color: "#2d2d2d;"}}>Newsletter</h4>
                                            <form action="#">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email'" placeholder="Enter email" required/>
                                                </div>
                                                <button className="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn" type="submit">Subscribe</button>
                                            </form>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </main>
                {/* <footer>
                    <div className="footer-wrapper">

                        <div className="footer-area footer-padding">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-8">
                                        <div className="single-footer-caption mb-50">
                                            <div className="single-footer-caption mb-30">

                                                <div className="footer-logo mb-35">
                                                    <a href="index-2.html"><img src="assets/img/logo/logo2_footer.png" alt/></a>
                                                </div>
                                                <div className="footer-tittle">
                                                    <div className="footer-pera">
                                                        <p>Duis aute irure dolor inasfa reprehenderit in voluptate velit esse cillum</p>
                                                    </div>

                                                    <ul className="footer-social">
                                                        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                                        <li><a href="https://bit.ly/sai4ull"><i className="fab fa-facebook"></i></a></li>
                                                        <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                                        <li> <a href="#"><i className="fab fa-youtube"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Navigation</h4>
                                                <ul>
                                                    <li><a href="#">Home</a></li>
                                                    <li><a href="#">About</a></li>
                                                    <li><a href="#">Services</a></li>
                                                    <li><a href="#">Blog</a></li>
                                                    <li><a href="#">Contact</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Services</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li><a href="#"> Real State</a></li>
                                                    <li><a href="#">Commercial</a></li>
                                                    <li><a href="#">Construction</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Support</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li><a href="#"> Real State</a></li>
                                                    <li><a href="#">Commercial</a></li>
                                                    <li><a href="#">Construction</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-8">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle mb-50">
                                                <h4>Contact Us</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li className="number2"><a href="#"><span className="__cf_email__" data-cfemail="c4a7abaab0a5a7b084a7a5b6b3a5b7aceaa7aba9">[email&#160;protected]</span></a></li>
                                                    <li className="number"><a href="#">10 (87) 738-3940</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-bottom-area">
                            <div className="container">
                                <div className="footer-border">
                                    <div className="row">
                                        <div className="col-xl-12 ">
                                            <div className="footer-copy-right text-center">
                                                <p>Copyright &copy;<script data-cfasync="false" src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart color-danger" aria-hidden="true"></i> by <a href="https://colorlib.com/" target="_blank" rel="nofollow noopener">Colorlib</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </footer>

                <div id="back-top">
                    <a className="wrapper" title="Go to Top" href="#">
                        <div className="arrows-container">
                            <div className="arrow arrow-one">
                            </div>
                            <div className="arrow arrow-two">
                            </div>
                        </div>
                    </a>
                </div> */}
            </div>
        )
    }
}

export default BlogDetails
