import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <div>
                <footer>
                    <div className="footer-wrapper">

                        <div className="footer-area footer-padding">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-xl-5 col-lg-5 col-md-6 col-sm-8">
                                        <div className="single-footer-caption mb-50">
                                            <div className="single-footer-caption mb-30">

                                                <div className="footer-logo mb-35">
                                                    {/* <a href="/"><img src="assets/img/logo/logo2_footer.png" alt /></a> */}
                                                    <a href="/"><img src="assets/img/logo/logo2.png" alt="mercury logo" style={{ width: '15rem' }} /></a>
                                                </div>
                                                <div className="footer-tittle">
                                                    <div className="footer-pera">
                                                        <p>Pioneering the frontier of celestial commercial real estate, Mercury Corporate Space delivers unparalleled office and showroom experiences. From classic offices to breathtaking balcony views, our spaces are designed for visionary enterprises looking to thrive in the cosmos.</p>
                                                    </div>

                                                    <ul className="footer-social">
                                                        <li><a href="https://www.instagram.com/mercurycorporatespace/"><i className="fab fa-instagram"></i></a></li>
                                                        <li><a href="https://www.facebook.com/mercurycorporatespace/"><i className="fab fa-facebook"></i></a></li>
                                                        {/* <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                                        <li> <a href="#"><i className="fab fa-youtube"></i></a></li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Navigation</h4>
                                                <ul>
                                                    <li><a href="/">Home</a></li>
                                                    {/* <li><a href="/about">About</a></li> */}
                                                    {/* <li><a href="/property">Property</a></li>
                                                    <li><a href="#">Services</a></li>
                                                    <li><a href="/blog">Blog</a></li> */}
                                                    <li><a href="/about">About Us</a></li>
                                                    <li><a href="/amenities">Amenities</a></li>
                                                    <li><a href="/plan">Plan</a></li>
                                                    <li><a href="/contact">Contact</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Services</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li><a href="#"> Real State</a></li>
                                                    <li><a href="#">Commercial</a></li>
                                                    <li><a href="#">Construction</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Support</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li><a href="#"> Real State</a></li>
                                                    <li><a href="#">Commercial</a></li>
                                                    <li><a href="#">Construction</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-8"> */}
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle mb-50">
                                                <h4>Contact Us</h4>
                                                <ul>
                                                    <li className='d-flex'>
                                                        <i className="fa fa-map-marker" style={{ padding: '5px', fontSize: '18px' }}></i>
                                                        <a href="https://maps.app.goo.gl/mNQjGCGMpJanRoESA">Mercury Corporate Space,<br />
                                                            Madhapar Chokdi, Jamnagar Road,<br /> Opp. Siddhivinayak Showroom,<br /> Rajkot</a>
                                                    </li>
                                                    <li className="d-flex">
                                                        <i className="fa fa-envelope" style={{ padding: '5px', fontSize: '18px' }}></i>
                                                        {/* <a href="#">
                                                            <span className="__cf_email__" data-cfemail="6f0c00011b0e0c1b2f0c0e1d180e1c07410c0002">[email&#160;protected]</span>
                                                        </a> */}
                                                        <a href='mailto:mercurycorporatespace@gmail.com'>mercurycorporatespace@gmail.com</a>
                                                    </li>
                                                    {/* <li className="number"><a href="#">10 (87) 738-3940</a></li> */}
                                                    <li className="d-flex">
                                                        <i className="fa fa-phone" style={{ padding: '5px', fontSize: '18px' }}></i>
                                                        <a href="tel:+917900026000">+91 79000 26000</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-bottom-area">
                            <div className="container">
                                <div className="footer-border">
                                    <div className="row">
                                        <div className="col-xl-12 ">
                                            <div className="footer-copy-right text-center">
                                                {/* <p>Copyright &copy;<script data-cfasync="false" src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart color-danger" aria-hidden="true"></i> by <a href="https://colorlib.com/" target="_blank" rel="nofollow noopener">Colorlib</a></p> */}
                                                <p>© 2023 Mercury Corporate Space. All rights reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </footer>

                <div id="back-top">
                    <a className="wrapper" title="Go to Top" href="#">
                        <div className="arrows-container">
                            <div className="arrow arrow-one">
                            </div>
                            <div className="arrow arrow-two">
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}

export default Footer
