import React, { Component } from 'react'

export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenu: '/',
            current_url: '',
        }
    }

    async componentDidMount() {
        let current_url = window.location.pathname.replace("/", "");
        await this.setState({ selectedMenu: current_url ? current_url : '/', current_url })
    }

    onChangeMenu = async (tab) => {
        await this.setState({ selectedMenu: tab, current_url: '' });
    }
    render() {
        return (
            <div>
                <div id="preloader-active">
                    <div className="preloader d-flex align-items-center justify-content-center">
                        <div className="preloader-inner position-relative">
                            <div className="preloader-circle"></div>
                            <div className="preloader-img pere-text">
                                <img src="assets/img/icon/loder.png" alt="loder" />
                            </div>
                        </div>
                    </div>
                </div>

                <header>
                    <div className="header-area">
                        <div className="main-header">
                            <div className="header-top header-sticky">
                                <div className="container">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap position-relative">

                                        <div className="left-side d-flex align-items-center">
                                            <div className="logo">
                                                {/* <a href="/"><img src="assets/img/logo/logo2-removebg-preview.png" alt style={{ height: '8rem' }} /></a> */}
                                                <a href="/"><img src="assets/img/logo/logo3.png" alt="logo" style={{ height: '5rem' }} /></a>
                                            </div>

                                            <div className="main-menu d-none d-lg-block">
                                                <nav>
                                                    <ul id="navigation">
                                                        <li className={`${this.state.selectedMenu === '/' ? 'active' : ''}`}>
                                                            <a href="/" onClick={() => { this.onChangeMenu('/') }}>Home</a>
                                                        </li>
                                                        {/* <li><a href="/property">Property</a></li> */}
                                                        <li className={`${this.state.selectedMenu === 'about' ? 'active' : ''}`}>
                                                            <a href="/about" onClick={() => { this.onChangeMenu('about') }}>About Us</a>
                                                        </li>
                                                        <li className={`${this.state.selectedMenu === 'amenities' ? 'active' : ''}`}>
                                                            <a href="/amenities" onClick={() => { this.onChangeMenu('amenities') }}>Amenities</a>
                                                        </li>
                                                        <li className={`${this.state.selectedMenu === 'plan' ? 'active' : ''}`}>
                                                            <a href="/plan" onClick={() => { this.onChangeMenu('plan') }}>Plan</a>
                                                        </li>

                                                        {/* <li><a href="/blog">Blog</a>
                                                            <ul className="submenu">
                                                                <li><a href="/blog">Blog</a></li>
                                                                <li><a href="/blog_details">Blog Details</a></li>
                                                                <li><a href="elements">Elements</a></li>
                                                            </ul>
                                                        </li> */}
                                                        <li className={`${this.state.selectedMenu === 'contact' ? 'active' : ''}`}>
                                                            <a href="/contact" onClick={() => { this.onChangeMenu('contact') }}>Contact</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="header-right-btn f-right">
                                            {/* <a href="#" className="header-btn2">Call Us: <span>+10 (78) 356 3276</span></a>
                                            <a href="/login" className="btn_1">Sign In</a> */}
                                            <a href="assets/brochure/Mercury_Digital_Brochure.pdf" download="Mercury_Digital_Brochure.pdf" className="btn_1">
                                                Download Brochure
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <div className="mobile_menu d-block d-lg-none"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="header-bottom">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-xxl-8 col-xl-10 col-lg-11">

                                            <form action="#" className="search-box">
                                                <div className="input-form">
                                                    <input type="text" placeholder="Enter Keyword.." />

                                                    <div className="icon">
                                                        <i className="ti-search"></i>
                                                    </div>
                                                </div>
                                                <div className="select-form">
                                                    <div className="select-itms">
                                                        <select name="select" id="select1">
                                                            <option value>Rent</option>
                                                            <option value>Rent 1</option>
                                                            <option value>Rent 2</option>
                                                            <option value>Rent 3</option>
                                                            <option value>Rent 4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="select-form select-form2">
                                                    <div className="select-itms">
                                                        <select name="select" id="select2">
                                                            <option value>Bed</option>
                                                            <option value>Bed 1</option>
                                                            <option value>Bed 2</option>
                                                            <option value>Bed 3</option>
                                                            <option value>Bed 4</option>
                                                        </select>
                                                        <div className="select-items">
                                                            <img src="assets/img/icon/bed.svg" alt />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="select-form select-form2">
                                                    <div className="select-itms">
                                                        <select name="select" id="select3">
                                                            <option value>Bath</option>
                                                            <option value>Bath 1</option>
                                                            <option value>Bath 2</option>
                                                            <option value>Bath 3</option>
                                                            <option value>Bath 4</option>
                                                        </select>
                                                    </div>
                                                    <div className="select-items">
                                                        <img src="assets/img/icon/bath.svg" alt />
                                                    </div>
                                                </div>
                                                <div className="search-form">
                                                    <button>Search</button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}

export default Header
