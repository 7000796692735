import React, { Component } from 'react'

export class Amenities extends Component {
    render() {
        return (
            <div>
                <main>

                    <div className="slider-area">
                        <div className="single-slider slider-bg3 hero-overly slider-height2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-10">

                                        <div className="hero-caption hero-caption4">
                                            <h1>Amenities</h1>
                                            <p>Discover the array of unparalleled amenities we offer at Mercury Corporate Space, ensuring each venture isn't just about business, but a celestial experience in comfort and innovation.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="about-area1 about-area2">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            <h2>Separate Owner's List:</h2>
                                            <p>Elevate your corporate experience with a distinct owner's lift. This dedicated feature ensures exclusive and convenient access to your office space, setting a tone of privilege and efficiency for your business operations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/amenities/SEPARATE_OWNER'S_LIFT.png" alt="amenitiest"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/amenities/FABULOUS_BUILDING_ELEVATION_FOR_A_CORPORATE_LOOK.webp" alt="amenitiest"/>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            <h2>Fabulous Building Elevation for a Corporate Look:</h2>
                                            <p>Our building boasts a remarkable exterior design, exuding corporate sophistication. The impressive elevation not only enhances the visual appeal but also communicates professionalism, making it an ideal choice for businesses seeking a prestigious address.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            <h2>Located at the Most Precious Part of the City:</h2>
                                            <p>Strategically positioned in the city's most prestigious district, our corporate building enjoys a prime location. This strategic advantage provides easy access to key business hubs, governmental institutions, and cultural centers, reflecting the epitome of corporate success.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/amenities/LOCATED_AT_THE_MOST_PRECIOUS_PART_OF_THE_CITY.png" alt="amenitiest"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="about-area1 about-area2 background-off-white">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/amenities/AUTO_DOOR_ELEVATORS_WITH_GOODS.png" alt="amenitiest"/>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            <h2>Auto Door Elevators with Goods:</h2>
                                            <p>Streamline your business logistics with our advanced elevators featuring automatic doors for goods transport. Whether you're managing inventory or office supplies, these elevators ensure seamless and efficient operations, saving valuable time and resources.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            <h2>Attractive Luxurious Space for Showrooms:</h2>
                                            <p>Explore dedicated and opulent showroom spaces within our corporate building. These elegantly designed areas offer a perfect platform to showcase your products or services, leaving a lasting impression on clients and partners.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/amenities/ATTRACTIVE_LUXURIOUS_SPACE_FOR_SHOWROOMS.png" alt="amenitiest"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/amenities/CCTV_SURVEILLANCE.png" alt="amenitiest"/>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            <h2>CCTV Surveillance:</h2>
                                            <p>Your corporate security is paramount. Our property is equipped with state-of-the-art CCTV surveillance systems, ensuring 24/7 monitoring and peace of mind for all occupants.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="about-area1 about-area2">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            <h2>Reception Foyer and Waiting Foyer:</h2>
                                            <p>Create a warm and welcoming first impression for your clients and associates. Our reception and waiting foyers are meticulously designed spaces that provide comfort and hospitality, reinforcing your commitment to professionalism.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/amenities/RECEPTION_FOYER_AND_WAITING_FOYER.png" alt="amenitiest"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/amenities/DG_POWER_BACKUP_FOR_COMMON_AREAS.png" alt="amenitiest"/>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            <h2>DG Power Backup for Common Areas:</h2>
                                            <p>Reliability is key in corporate settings. Our building features a robust DG power backup system for common areas, ensuring uninterrupted services such as lighting and elevators, even during power disruptions.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            <h2>Sufficient Parking:</h2>
                                            <p>Convenience extends to parking solutions. Our corporate building offers ample parking spaces, ensuring that employees and clients can access your premises without the hassle of searching for parking, promoting an efficient and productive work environment.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/amenities/SUFFICIENT_PARKING.png" alt="amenitiest"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

            </div>
        )
    }
}

export default Amenities
