import React from 'react'
import { useLocation } from 'react-router-dom';
import { Header } from '../header';
import { Footer } from '../footer';

function Layout({ children }) {
    const { pathname } = useLocation();
    return (
        <div>
            {(pathname !== "/login" && pathname !== "/register") ? <Header /> : null}
            <div>{children}</div>
            {(pathname !== "/login" && pathname !== "/register") ? <Footer /> : null}
        </div>
    )
}

export default Layout
