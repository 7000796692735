import React, { Component } from 'react'

export class Blog extends Component {
    render() {
        return (
            <div>
                {/* <div id="preloader-active">
                    <div className="preloader d-flex align-items-center justify-content-center">
                        <div className="preloader-inner position-relative">
                            <div className="preloader-circle"></div>
                            <div className="preloader-img pere-text">
                                <img src="assets/img/icon/loder.png" alt="loder"/>
                            </div>
                        </div>
                    </div>
                </div>

                <header>
                    <div className="header-area">
                        <div className="main-header">
                            <div className="header-top header-sticky">
                                <div className="container">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap position-relative">

                                        <div className="left-side d-flex align-items-center">
                                            <div className="logo">
                                                <a href="index-2.html"><img src="assets/img/logo/logo.png" alt/></a>
                                            </div>

                                            <div className="main-menu d-none d-lg-block">
                                                <nav>
                                                    <ul id="navigation">
                                                        <li><a href="index-2.html">Home</a></li>
                                                        <li><a href="property.html">Property</a></li>
                                                        <li><a href="about.html">About</a></li>
                                                        <li><a href="blog.html">Blog</a>
                                                            <ul className="submenu">
                                                                <li><a href="blog.html">Blog</a></li>
                                                                <li><a href="blog_details.html">Blog Details</a></li>
                                                                <li><a href="elements.html">Elements</a></li>
                                                            </ul>
                                                        </li>
                                                        <li><a href="contact.html">Contact</a></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="header-right-btn f-right">
                                            <a href="#" className="header-btn2">Call Us: <span>+10 (78) 356 3276</span></a>
                                            <a href="login.html" className="btn_1">Sign In</a>
                                        </div>

                                        <div className="col-12">
                                            <div className="mobile_menu d-block d-lg-none"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="header-bottom">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-xxl-8 col-xl-10 col-lg-11">

                                            <form action="#" className="search-box">
                                                <div className="input-form">
                                                    <input type="text" placeholder="Enter Keyword.."/>

                                                        <div className="icon">
                                                            <i className="ti-search"></i>
                                                        </div>
                                                </div>
                                                <div className="select-form">
                                                    <div className="select-itms">
                                                        <select name="select" id="select1">
                                                            <option value>Rent</option>
                                                            <option value>Rent 1</option>
                                                            <option value>Rent 2</option>
                                                            <option value>Rent 3</option>
                                                            <option value>Rent 4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="select-form select-form2">
                                                    <div className="select-itms">
                                                        <select name="select" id="select2">
                                                            <option value>Bed</option>
                                                            <option value>Bed 1</option>
                                                            <option value>Bed 2</option>
                                                            <option value>Bed 3</option>
                                                            <option value>Bed 4</option>
                                                        </select>
                                                        <div className="select-items">
                                                            <img src="assets/img/icon/bed.svg" alt/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="select-form select-form2">
                                                    <div className="select-itms">
                                                        <select name="select" id="select3">
                                                            <option value>Bath</option>
                                                            <option value>Bath 1</option>
                                                            <option value>Bath 2</option>
                                                            <option value>Bath 3</option>
                                                            <option value>Bath 4</option>
                                                        </select>
                                                    </div>
                                                    <div className="select-items">
                                                        <img src="assets/img/icon/bath.svg" alt/>
                                                    </div>
                                                </div>
                                                <div className="search-form">
                                                    <button>Search</button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header> */}
                <main>

                    <div className="slider-area">
                        <div className="single-slider slider-bg4 hero-overly slider-height2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-10">

                                        <div className="hero-caption hero-caption2">
                                            <h1>Blog</h1>
                                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className="blog_area top-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 mb-5 mb-lg-0">
                                    <div className="blog_left_sidebar">
                                        <article className="blog_item">
                                            <div className="blog_item_img">
                                                <img className="card-img rounded-0" src="assets/img/blog/single_blog_1.jpg" alt/>
                                                    <a href="#" className="blog_item_date">
                                                        <h3>15</h3>
                                                        <p>Jan</p>
                                                    </a>
                                            </div>
                                            <div className="blog_details">
                                                <a className="d-inline-block" href="blog_details.html">
                                                    <h2 className="blog-head" style={{color: "#2d2d2d;"}}>Google inks pact for new 35-storey office</h2>
                                                </a>
                                                <p>That dominion stars lights dominion divide years for fourth have don't stars is that
                                                    he earth it first without heaven in place seed it second morning saying.</p>
                                                <ul className="blog-info-link">
                                                    <li><a href="#"><i className="fa fa-user"></i> Travel, Lifestyle</a></li>
                                                    <li><a href="#"><i className="fa fa-comments"></i> 03 Comments</a></li>
                                                </ul>
                                            </div>
                                        </article>
                                        <article className="blog_item">
                                            <div className="blog_item_img">
                                                <img className="card-img rounded-0" src="assets/img/blog/single_blog_2.jpg" alt/>
                                                    <a href="#" className="blog_item_date">
                                                        <h3>15</h3>
                                                        <p>Jan</p>
                                                    </a>
                                            </div>
                                            <div className="blog_details">
                                                <a className="d-inline-block" href="blog_details.html">
                                                    <h2 className="blog-head" style={{color: "#2d2d2d;"}}>Google inks pact for new 35-storey office</h2>
                                                </a>
                                                <p>That dominion stars lights dominion divide years for fourth have don't stars is that
                                                    he earth it first without heaven in place seed it second morning saying.</p>
                                                <ul className="blog-info-link">
                                                    <li><a href="#"><i className="fa fa-user"></i> Travel, Lifestyle</a></li>
                                                    <li><a href="#"><i className="fa fa-comments"></i> 03 Comments</a></li>
                                                </ul>
                                            </div>
                                        </article>
                                        <article className="blog_item">
                                            <div className="blog_item_img">
                                                <img className="card-img rounded-0" src="assets/img/blog/single_blog_3.jpg" alt/>
                                                    <a href="#" className="blog_item_date">
                                                        <h3>15</h3>
                                                        <p>Jan</p>
                                                    </a>
                                            </div>
                                            <div className="blog_details">
                                                <a className="d-inline-block" href="blog_details.html">
                                                    <h2 className="blog-head" style={{color: "#2d2d2d;"}}>Google inks pact for new 35-storey office</h2>
                                                </a>
                                                <p>That dominion stars lights dominion divide years for fourth have don't stars is that
                                                    he earth it first without heaven in place seed it second morning saying.</p>
                                                <ul className="blog-info-link">
                                                    <li><a href="#"><i className="fa fa-user"></i> Travel, Lifestyle</a></li>
                                                    <li><a href="#"><i className="fa fa-comments"></i> 03 Comments</a></li>
                                                </ul>
                                            </div>
                                        </article>
                                        <article className="blog_item">
                                            <div className="blog_item_img">
                                                <img className="card-img rounded-0" src="assets/img/blog/single_blog_4.jpg" alt/>
                                                    <a href="#" className="blog_item_date">
                                                        <h3>15</h3>
                                                        <p>Jan</p>
                                                    </a>
                                            </div>
                                            <div className="blog_details">
                                                <a className="d-inline-block" href="blog_details.html">
                                                    <h2 className="blog-head" style={{color: "#2d2d2d;"}}>Google inks pact for new 35-storey office</h2>
                                                </a>
                                                <p>That dominion stars lights dominion divide years for fourth have don't stars is that
                                                    he earth it first without heaven in place seed it second morning saying.</p>
                                                <ul className="blog-info-link">
                                                    <li><a href="#"><i className="fa fa-user"></i> Travel, Lifestyle</a></li>
                                                    <li><a href="#"><i className="fa fa-comments"></i> 03 Comments</a></li>
                                                </ul>
                                            </div>
                                        </article>
                                        <article className="blog_item">
                                            <div className="blog_item_img">
                                                <img className="card-img rounded-0" src="assets/img/blog/single_blog_5.jpg" alt/>
                                                    <a href="#" className="blog_item_date">
                                                        <h3>15</h3>
                                                        <p>Jan</p>
                                                    </a>
                                            </div>
                                            <div className="blog_details">
                                                <a className="d-inline-block" href="blog_details.html">
                                                    <h2 className="blog-head" style={{color: "#2d2d2d;"}}>Google inks pact for new 35-storey office</h2>
                                                </a>
                                                <p>That dominion stars lights dominion divide years for fourth have don't stars is that
                                                    he earth it first without heaven in place seed it second morning saying.</p>
                                                <ul className="blog-info-link">
                                                    <li><a href="#"><i className="fa fa-user"></i> Travel, Lifestyle</a></li>
                                                    <li><a href="#"><i className="fa fa-comments"></i> 03 Comments</a></li>
                                                </ul>
                                            </div>
                                        </article>
                                        <nav className="blog-pagination justify-content-center d-flex">
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <a href="#" className="page-link" aria-label="Previous">
                                                        <i className="ti-angle-left"></i>
                                                    </a>
                                                </li>
                                                <li className="page-item">
                                                    <a href="#" className="page-link">1</a>
                                                </li>
                                                <li className="page-item active">
                                                    <a href="#" className="page-link">2</a>
                                                </li>
                                                <li className="page-item">
                                                    <a href="#" className="page-link" aria-label="Next">
                                                        <i className="ti-angle-right"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="blog_right_sidebar">
                                        <aside className="single_sidebar_widget search_widget">
                                            <form action="#">
                                                <div className="form-group m-0">
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Search Keyword"/>
                                                            <div className="input-group-append d-flex">
                                                                <button className="boxed-btn2" type="button">Search</button>
                                                            </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </aside>
                                        <aside className="single_sidebar_widget post_category_widget">
                                            <h4 className="widget_title" style={{color: "#2d2d2d;"}}>Category</h4>
                                            <ul className="list cat-list">
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <p>Resaurant food</p>
                                                        <p>(37)</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <p>Travel news</p>
                                                        <p>(10)</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <p>Modern technology</p>
                                                        <p>(03)</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <p>Product</p>
                                                        <p>(11)</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <p>Inspiration</p>
                                                        <p>21</p>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="d-flex">
                                                        <p>Health Care (21)</p>
                                                        <p>09</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </aside>
                                        <aside className="single_sidebar_widget popular_post_widget">
                                            <h3 className="widget_title" style={{color: "#2d2d2d;"}}>Recent Post</h3>
                                            <div className="media post_item">
                                                <img src="assets/img/post/post_1.jpg" alt="post"/>
                                                    <div className="media-body">
                                                        <a href="blog_details.html">
                                                            <h3 style={{color: "#2d2d2d;"}}>From life was you fish...</h3>
                                                        </a>
                                                        <p>January 12, 2019</p>
                                                    </div>
                                            </div>
                                            <div className="media post_item">
                                                <img src="assets/img/post/post_2.jpg" alt="post"/>
                                                    <div className="media-body">
                                                        <a href="blog_details.html">
                                                            <h3 style={{color: "#2d2d2d;"}}>The Amazing Hubble</h3>
                                                        </a>
                                                        <p>02 Hours ago</p>
                                                    </div>
                                            </div>
                                            <div className="media post_item">
                                                <img src="assets/img/post/post_3.jpg" alt="post"/>
                                                    <div className="media-body">
                                                        <a href="blog_details.html">
                                                            <h3 style={{color: "#2d2d2d;"}}>Astronomy Or Astrology</h3>
                                                        </a>
                                                        <p>03 Hours ago</p>
                                                    </div>
                                            </div>
                                            <div className="media post_item">
                                                <img src="assets/img/post/post_4.jpg" alt="post"/>
                                                    <div className="media-body">
                                                        <a href="blog_details.html">
                                                            <h3 style={{color: "#2d2d2d;"}}>Asteroids telescope</h3>
                                                        </a>
                                                        <p>01 Hours ago</p>
                                                    </div>
                                            </div>
                                        </aside>
                                        <aside className="single_sidebar_widget tag_cloud_widget">
                                            <h4 className="widget_title" style={{color: "#2d2d2d;"}}>Tag Clouds</h4>
                                            <ul className="list">
                                                <li>
                                                    <a href="#">project</a>
                                                </li>
                                                <li>
                                                    <a href="#">love</a>
                                                </li>
                                                <li>
                                                    <a href="#">technology</a>
                                                </li>
                                                <li>
                                                    <a href="#">travel</a>
                                                </li>
                                                <li>
                                                    <a href="#">restaurant</a>
                                                </li>
                                                <li>
                                                    <a href="#">life style</a>
                                                </li>
                                                <li>
                                                    <a href="#">design</a>
                                                </li>
                                                <li>
                                                    <a href="#">illustration</a>
                                                </li>
                                            </ul>
                                        </aside>
                                        <aside className="single_sidebar_widget instagram_feeds">
                                            <h4 className="widget_title" style={{color: "#2d2d2d;"}}>Instagram Feeds</h4>
                                            <ul className="instagram_row flex-wrap">
                                                <li>
                                                    <a href="#">
                                                        <img className="img-fluid" src="assets/img/post/post_5.jpg" alt/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img className="img-fluid" src="assets/img/post/post_6.jpg" alt/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img className="img-fluid" src="assets/img/post/post_7.jpg" alt/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img className="img-fluid" src="assets/img/post/post_8.jpg" alt/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img className="img-fluid" src="assets/img/post/post_9.jpg" alt/>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img className="img-fluid" src="assets/img/post/post_10.jpg" alt/>
                                                    </a>
                                                </li>
                                            </ul>
                                        </aside>
                                        <aside className="single_sidebar_widget newsletter_widget">
                                            <h4 className="widget_title" style={{color: "#2d2d2d;"}}>Newsletter</h4>
                                            <form action="#">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email'" placeholder="Enter email" required/>
                                                </div>
                                                <button className="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn" type="submit">Subscribe</button>
                                            </form>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </main>
                {/* <footer>
                    <div className="footer-wrapper">

                        <div className="footer-area footer-padding">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-8">
                                        <div className="single-footer-caption mb-50">
                                            <div className="single-footer-caption mb-30">

                                                <div className="footer-logo mb-35">
                                                    <a href="index-2.html"><img src="assets/img/logo/logo2_footer.png" alt/></a>
                                                </div>
                                                <div className="footer-tittle">
                                                    <div className="footer-pera">
                                                        <p>Duis aute irure dolor inasfa reprehenderit in voluptate velit esse cillum</p>
                                                    </div>

                                                    <ul className="footer-social">
                                                        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                                        <li><a href="https://bit.ly/sai4ull"><i className="fab fa-facebook"></i></a></li>
                                                        <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                                        <li> <a href="#"><i className="fab fa-youtube"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Navigation</h4>
                                                <ul>
                                                    <li><a href="#">Home</a></li>
                                                    <li><a href="#">About</a></li>
                                                    <li><a href="#">Services</a></li>
                                                    <li><a href="#">Blog</a></li>
                                                    <li><a href="#">Contact</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Services</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li><a href="#"> Real State</a></li>
                                                    <li><a href="#">Commercial</a></li>
                                                    <li><a href="#">Construction</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Support</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li><a href="#"> Real State</a></li>
                                                    <li><a href="#">Commercial</a></li>
                                                    <li><a href="#">Construction</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-8">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle mb-50">
                                                <h4>Contact Us</h4>
                                                <ul>
                                                    <li><a href="#">Drone Mapping</a></li>
                                                    <li className="number2"><a href="#"><span className="__cf_email__" data-cfemail="60030f0e1401031420030112170113084e030f0d">[email&#160;protected]</span></a></li>
                                                    <li className="number"><a href="#">10 (87) 738-3940</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-bottom-area">
                            <div className="container">
                                <div className="footer-border">
                                    <div className="row">
                                        <div className="col-xl-12 ">
                                            <div className="footer-copy-right text-center">
                                                <p>Copyright &copy;<script data-cfasync="false" src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart color-danger" aria-hidden="true"></i> by <a href="https://colorlib.com/" target="_blank" rel="nofollow noopener">Colorlib</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </footer>

                <div id="back-top">
                    <a className="wrapper" title="Go to Top" href="#">
                        <div className="arrows-container">
                            <div className="arrow arrow-one">
                            </div>
                            <div className="arrow arrow-two">
                            </div>
                        </div>
                    </a>
                </div> */}
            </div>
        )
    }
}

export default Blog
