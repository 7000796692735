import React, { Component } from 'react'

export class Register extends Component {
    render() {
        return (
            <div>
                <main className="login-bg">

                    <div className="register-form-area">
                        <div className="register-form text-center">

                            <div className="register-heading">
                                <span>Sign Up</span>
                                <p>Create your account to get full access</p>
                            </div>

                            <div className="input-box">
                                <div className="single-input-fields">
                                    <label>Full name</label>
                                    <input type="text" placeholder="Enter full name"/>
                                </div>
                                <div className="single-input-fields">
                                    <label>Email Address</label>
                                    <input type="email" placeholder="Enter email address"/>
                                </div>
                                <div className="single-input-fields">
                                    <label>Password</label>
                                    <input type="password" placeholder="Enter Password"/>
                                </div>
                                <div className="single-input-fields">
                                    <label>Confirm Password</label>
                                    <input type="password" placeholder="Confirm Password"/>
                                </div>
                            </div>

                            <div className="register-footer">
                                <p> Already have an account? <a href="/login"> Login</a> here</p>
                                <button className="submit-btn3">Sign Up</button>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
        )
    }
}

export default Register
