import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Property from './pages/Property';
import About from './pages/About';
import Blog from './pages/Blog/Blog';
import BlogDetails from './pages/Blog/BlogDetails';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Register from './pages/Register';
import Layout from './components/layout/Layout';
import Plan from './pages/Plan';
import Amenities from './pages/Amenities';
import './App.css';

import ReactGA from 'react-ga';
const TRACKING_ID = "G-3BP465CVLX"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path='/' Component={Home} />
          <Route path='/property' Component={Property} />
          <Route path='/plan' Component={Plan} />
          <Route path='/about' Component={About} />
          <Route path='/amenities' Component={Amenities} />
          <Route path='/blog' Component={Blog} />
          <Route path='/blog_details' Component={BlogDetails} />
          <Route path='/contact' Component={Contact} />
          <Route path='/login' Component={Login} />
          <Route path='/register' Component={Register} />
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}

export default App;
