import React, { Component } from 'react'

export class Plan extends Component {
    render() {
        return (
            <div>
                <main>

                    <div className="slider-area">
                        <div className="single-slider slider-bg2 hero-overly slider-height2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-10">

                                        <div className="hero-caption hero-caption2">
                                            <h1>Our Plan</h1>
                                            <p>Navigate the blueprint of Mercury Corporate Space's visionary approach and strategic roadmap, as we chart the course for the future of interstellar commercial real estate.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <section className="latest-items section-padding">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-6">

                                    <div className="section-tittle mb-50 text-center ">
                                        <h2>Properties for Sale</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <div className="single-cat single-cat2 mb-30">
                                        <div className="cat-img">
                                            <img src="assets/img/gallery/items1.jpg" alt />
                                        </div>
                                        <div className="cat-cap">
                                            <div className="cap-top">
                                                <span>$ 6728</span>
                                                <h5><a href="#">Appartment</a></h5>
                                                <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                            </div>
                                            <div className="pricing d-flex justify-content-between align-items-center">
                                                <div className="single">
                                                    <img src="assets/img/icon/bed.svg" alt />
                                                    <span>2 Bedroom</span>
                                                </div>
                                                <div className="single">
                                                    <img src="assets/img/icon/bath.svg" alt />
                                                    <span>2 Bedroom</span>
                                                </div>
                                                <div className="single">
                                                    <img src="assets/img/icon/building.svg" alt />
                                                    <span>1050 Sq Ft</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <div className="single-cat single-cat2 mb-30">
                                        <div className="cat-img">
                                            <img src="assets/img/gallery/items2.jpg" alt />
                                        </div>
                                        <div className="cat-cap">
                                            <div className="cap-top">
                                                <span>$ 6728</span>
                                                <h5><a href="#">Office</a></h5>
                                                <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                            </div>
                                            <div className="pricing d-flex justify-content-between align-items-center">
                                                <div className="single">
                                                    <img src="assets/img/icon/bed.svg" alt />
                                                    <span>2 Bedroom</span>
                                                </div>
                                                <div className="single">
                                                    <img src="assets/img/icon/bath.svg" alt />
                                                    <span>2 Bedroom</span>
                                                </div>
                                                <div className="single">
                                                    <img src="assets/img/icon/building.svg" alt />
                                                    <span>1050 Sq Ft</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <div className="single-cat single-cat2 mb-30">
                                        <div className="cat-img">
                                            <img src="assets/img/gallery/items1.jpg" alt />
                                        </div>
                                        <div className="cat-cap">
                                            <div className="cap-top">
                                                <span>$ 6728</span>
                                                <h5><a href="#">Classic home</a></h5>
                                                <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                            </div>
                                            <div className="pricing d-flex justify-content-between align-items-center">
                                                <div className="single">
                                                    <img src="assets/img/icon/bed.svg" alt />
                                                    <span>2 Bedroom</span>
                                                </div>
                                                <div className="single">
                                                    <img src="assets/img/icon/bath.svg" alt />
                                                    <span>2 Bedroom</span>
                                                </div>
                                                <div className="single">
                                                    <img src="assets/img/icon/building.svg" alt />
                                                    <span>1050 Sq Ft</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <div className="single-cat single-cat2 mb-30">
                                        <div className="cat-img">
                                            <img src="assets/img/gallery/items1.jpg" alt />
                                        </div>
                                        <div className="cat-cap">
                                            <div className="cap-top">
                                                <span>$ 6728</span>
                                                <h5><a href="#">Appartment</a></h5>
                                                <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                            </div>
                                            <div className="pricing d-flex justify-content-between align-items-center">
                                                <div className="single">
                                                    <img src="assets/img/icon/bed.svg" alt />
                                                    <span>2 Bedroom</span>
                                                </div>
                                                <div className="single">
                                                    <img src="assets/img/icon/bath.svg" alt />
                                                    <span>2 Bedroom</span>
                                                </div>
                                                <div className="single">
                                                    <img src="assets/img/icon/building.svg" alt />
                                                    <span>1050 Sq Ft</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <div className="single-cat single-cat2 mb-30">
                                        <div className="cat-img">
                                            <img src="assets/img/gallery/items2.jpg" alt />
                                        </div>
                                        <div className="cat-cap">
                                            <div className="cap-top">
                                                <span>$ 6728</span>
                                                <h5><a href="#">Office</a></h5>
                                                <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                            </div>
                                            <div className="pricing d-flex justify-content-between align-items-center">
                                                <div className="single">
                                                    <img src="assets/img/icon/bed.svg" alt />
                                                    <span>2 Bedroom</span>
                                                </div>
                                                <div className="single">
                                                    <img src="assets/img/icon/bath.svg" alt />
                                                    <span>2 Bedroom</span>
                                                </div>
                                                <div className="single">
                                                    <img src="assets/img/icon/building.svg" alt />
                                                    <span>1050 Sq Ft</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <div className="single-cat single-cat2 mb-30">
                                        <div className="cat-img">
                                            <img src="assets/img/gallery/items1.jpg" alt />
                                        </div>
                                        <div className="cat-cap">
                                            <div className="cap-top">
                                                <span>$ 6728</span>
                                                <h5><a href="#">Classic home</a></h5>
                                                <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                            </div>
                                            <div className="pricing d-flex justify-content-between align-items-center">
                                                <div className="single">
                                                    <img src="assets/img/icon/bed.svg" alt />
                                                    <span>2 Bedroom</span>
                                                </div>
                                                <div className="single">
                                                    <img src="assets/img/icon/bath.svg" alt />
                                                    <span>2 Bedroom</span>
                                                </div>
                                                <div className="single">
                                                    <img src="assets/img/icon/building.svg" alt />
                                                    <span>1050 Sq Ft</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className="latest-items section-padding">
                        <div className="container mb-25">
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                        <div className="about-caption about-caption1">

                                            <div className="section-tittle section-tittle3 mb-25">

                                                <h2>Ground Floor Plan</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-center'>
                                    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                        <div className="about-img about-img1  ">
                                            <img src="assets/img/plan/GroundFloorPlan.webp" alt="floor-map"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mb-25">
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                        <div className="about-caption about-caption1">

                                            <div className="section-tittle section-tittle3 mb-25">

                                                <h2>1<sup>st</sup> Floor Plan</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-center'>
                                    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                        <div className="about-img about-img1  ">
                                            <img src="assets/img/plan/1stFloorPlan.webp" alt="floor-map"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mb-25">
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                        <div className="about-caption about-caption1">

                                            <div className="section-tittle section-tittle3 mb-25">

                                                <h2>2<sup>nd</sup> Floor Plan</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-center'>
                                    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                        <div className="about-img about-img1  ">
                                            <img src="assets/img/plan/2ndFloorPlan.webp" alt="floor-map"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mb-25">
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                        <div className="about-caption about-caption1">

                                            <div className="section-tittle section-tittle3 mb-25">

                                                <h2>3<sup>rd</sup> Floor Plan</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-center'>
                                    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                        <div className="about-img about-img1  ">
                                            <img src="assets/img/plan/3rdFloorPlan.webp" alt="floor-map"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mb-25">
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                        <div className="about-caption about-caption1">

                                            <div className="section-tittle section-tittle3 mb-25">

                                                <h2>4<sup>th</sup> Floor Plan</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-center'>
                                    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                        <div className="about-img about-img1  ">
                                            <img src="assets/img/plan/4thFloorPlan.webp" alt="floor-map"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mb-25">
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                        <div className="about-caption about-caption1">

                                            <div className="section-tittle section-tittle3 mb-25">

                                                <h2>5<sup>th</sup>-8<sup>th</sup> Floor Plan</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-center'>
                                    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                        <div className="about-img about-img1  ">
                                            <img src="assets/img/plan/5th-8thFloorPlan.webp" alt="floor-map"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mb-25">
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                        <div className="about-caption about-caption1">

                                            <div className="section-tittle section-tittle3 mb-25">

                                                <h2>9<sup>th</sup> Floor Plan</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-center'>
                                    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                        <div className="about-img about-img1  ">
                                            <img src="assets/img/plan/9thFloorPlan.webp" alt="floor-map"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mb-25">
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                        <div className="about-caption about-caption1">

                                            <div className="section-tittle section-tittle3 mb-25">

                                                <h2>10<sup>th</sup> Floor Plan</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-center'>
                                    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                        <div className="about-img about-img1  ">
                                            <img src="assets/img/plan/10thFloorPlan.webp" alt="floor-map"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mb-25">
                            <div className='row'>
                                <div className='row'>
                                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                        <div className="about-caption about-caption1">

                                            <div className="section-tittle section-tittle3 mb-25">

                                                <h2>11<sup>th</sup>-13<sup>th</sup> Floor Plan</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-center'>
                                    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                        <div className="about-img about-img1  ">
                                            <img src="assets/img/plan/11th-13thFloorPlan.webp" alt="floor-map"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    {/* <section className="latest-items section-padding">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                            <h2>Ground Floor Plan</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/plan/GroundFloorPlan.png" alt="floor-map"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="latest-items section-padding">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/plan/1stFloorPlan.png" alt="floor-map"/>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                        <h2>1<sup>st</sup> Floor Plan</h2>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="latest-items section-padding">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-9">
                                    <div className="about-caption about-caption1">

                                        <div className="section-tittle section-tittle3 mb-25">
                                        <h2>2<sup>nd</sup> Floor Plan</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="offset-xxl-1 col-xxl-5 col-xl-5 col-lg-6 col-md-8">

                                    <div className="about-img about-img1  ">
                                        <img src="assets/img/plan/2ndFloorPlan.png" alt="floor-map"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}


                    {/* <section className="categories-area section-bg">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-4">
                                    <div className="section-tittle section-tittle3 mb-20">
                                        <h2>Explore<br /> by Property Type</h2>
                                        <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                                        <a href="#" className="btn mt-30">View All Property</a>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 p-0">
                                    <div className="single-cat text-center mb-10">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/property1.svg" alt="floor-map"/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Home & Appartment</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 p-0">
                                    <div className="single-cat text-center mb-10">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/property2.svg" alt="floor-map"/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Vila</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 p-0">
                                    <div className="single-cat text-center mb-10">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/property3.svg" alt="floor-map""floor-map""floor-map""floor-map""floor-map"/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Studio</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 p-0">
                                    <div className="single-cat text-center mb-10">
                                        <div className="cat-icon">
                                            <img src="assets/img/icon/property4.svg" alt="floor-map"/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5><a href="#">Office</a></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}


                    {/* <div className="visit-tailor-area fix">

                        <div className="tailor-details">
                            <h2>Subscribe Newsletter</h2>
                            <p>Get started by choosing from one of our pre-built page templates to showcase your properties</p>
                            <div className="subscribe-now">
                                <input type="text" placeholder="Enter your email" />
                                <button className="submit-btn2">Subscribe</button>
                            </div>
                        </div>

                        <div className="tailor-offers"></div>
                    </div> */}

                </main>
            </div>
        )
    }
}

export default Plan
